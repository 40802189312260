import React from 'react'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import PageIllustration from '../../components/PageIllustration'
import Header from '../../components/Header'
import {Link} from "gatsby";

export default () => (
  <Layout>
        <Header />

           
        <div className="flex flex-col min-h-screen overflow-hidden">
        {/* Page header */}
        {/* <PageIllustration /> */}
{/*  Site header */}

{/*  Page content */}
<main className="flex-grow">
  
  <section className="relative">
  <PageIllustration />

          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 w-3/6 mt-8">
                <h1 className="h1 mb-4 text-4xl lg:text-5xl text-gray-800 dark:text-gray-300 font-extrabold leading-tight tracking-tighter" data-aos="zoom-y-out">
                  Thank you!</h1>
                <p className="text-xl text-gray-400 mb-8 " data-aos="zoom-y-out" data-aos-delay="200">
                  Your message has been received! We'll get back to you shortly, thanks for contacting us! :-)
                </p>
                <Link to="/" className="font-medium w-3/5 mx-auto inline-flex items-center justify-center 
                  border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 
                  ease-in-out">Back to Home</Link>
              </div>

              
            </div>
          </div>
        </section>



</main>

</div>

      <Footer />
      </Layout>
)
